<template>
  <div id="sign-in" class="auth">
    <!-- <h1 class="header">Password Reset</h1> -->
    <form action="" class="auth-form" @submit.prevent="sendResetEmail">
      <fieldset :disabled="loading">
        <div class="input-group">
          <input
            v-model="email"
            type="email"
            placeholder=" "
            id="email"
            required
            autocomplete="email"
          />
          <label for="email">Email</label>
          <p class="error-info">Please enter a valid email</p>
        </div>
        <button type="submit" :class="{ loading }">
          <template v-if="!loading">Reset Password</template>
        </button>
      </fieldset>
      <h3>{{ message }}</h3>
    </form>
    <div class="login-signup-now">
      Remembered your password?
      <router-link :to="{ name: 'SignIn' }">Sign in</router-link>.
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase-config";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      message: null,
      loading: false,
    };
  },
  methods: {
    async sendResetEmail() {
      this.loading = true;
      try {
        await auth.sendPasswordResetEmail(this.email);
        this.message = "An email has been sent to the provided email address";
      } catch (error) {
        this.message = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sign-in {
  color: white;

  .forgot-password {
    color: white;
    font-size: 1.3rem;
  }
}

.header {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 28px;
}
</style>
